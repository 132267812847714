import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,CHeaderNav,CHeaderToggler,CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyHeaderProfile,
	MyButton,
	MyDialogform,
	MyDialoglookup,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Rekammedisform 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	const { tabel_id } 			= useParams(); 
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const uRekammedisID				= parseInt(tabel_id)||0;

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const uLastinitObj 					= useSelector(state => state.gInitRMSelected);

	const [uDatamainObj,setDatamainObj]		= React.useState({})
	const [uDataanamnaseArr,setDataanamanseArr]	= React.useState([])
	const [uDatadiagnosaArr,setDatadiagnosaArr]	= React.useState([])
	const [uToggleditObj,setToggleditObj]	= React.useState({})
	const [uDataloading,setDataloading] 	= React.useState(false);
	const [uTriggerediting,setTriggerediting] 	= React.useState(false);

	//--DLG_FORM--/
	const [uDlgformObj,setDlgformObj]		= React.useState({})
	const [uISDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//---DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKlikBack = () => { uNavigate(-1); }
	const hdlKlikToggleedit = (_IDTABEL,_JENIS) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0;
		_JENIS		= _JENIS || "";

		setToggleditObj({});
		if(uToggleditObj.jenis === _JENIS && uToggleditObj.tabel_id === _IDTABEL) {
			return;
		}

		const vArridx	= UFunc.getObjectIndeks((uDatamainObj.items_arr||[]),_IDTABEL);
		let vValue		= (((uDatamainObj.items_arr||[])[vArridx] || {}).diagnosa_ket || "").trim();
		if(_JENIS === "KETGIGI")
			vValue		= (((uDatamainObj.items_arr||[])[vArridx] || {}).ket_gigi || "").trim();
		else if(_JENIS === "TINDAKAN")
			vValue		= (((uDatamainObj.items_arr||[])[vArridx] || {}).tindakan || "").trim();
		else if(_JENIS === "DIAGNOSA")
			vValue		= parseInt(((uDatamainObj.items_arr||[])[vArridx] || {}).diagnosa_id) || 0;

		setToggleditObj({
			tabel_id : _IDTABEL,
			jenis: _JENIS,
			value: vValue,
		});

		//--LANGSUNG_LOOKUP--/
		if(_JENIS === "DIAGNOSA") {
			hdlKlikDiagnosalookup();
			return;
		}
		//--END LANGSUNG_LOOKUP--/

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			let vElfocus	= document.getElementById("inpdiagnosaket");
			if(_JENIS === "KETGIGI")
				vElfocus	= document.getElementById("inpnomorgigi");
			else if(_JENIS === "TINDAKAN")
				vElfocus	= document.getElementById("inptindakan");

			vElfocus && vElfocus.focus();
		},350)
	}
	const hdlKDownToggleedit = (_IDTABEL,_EV) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0;

		if(_EV.keyCode === 27) {
			setToggleditObj({}); return;
		}

		if(_EV.keyCode === 13) {
			if (_EV.target.nodeName === "TEXTAREA") {
				if(_EV.shiftKey) return;
			}

			_EV.stopPropagation();
			_EV.preventDefault();
			hdlKlikToggleproses();
			return;
		}
	}
	const hdlKlikToggleproses = () => {
		//--VALIDASI--/
		//--END VALIDASI--/

		apiProsesedit()
	}
	const hdlKlikMobileedit = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;
		setToggleditObj({});

		const vDataitemsArr	= uDatamainObj.items_arr||[];
		const vArridx		= UFunc.getObjectIndeks(vDataitemsArr,_IDTABEL);
		const vCaptionupper	= ((vDataitemsArr[vArridx]||{}).caption_terapi||"").toUpperCase();

		setDlgformObj({
			dlg_header: "Edit: "+vCaptionupper,
			tabel_id: _IDTABEL,
			diagnosa_id: parseInt((vDataitemsArr[vArridx]||{}).diagnosa_id)||0,
			diagnosa: (vDataitemsArr[vArridx]||{}).diagnosa || "",
			diagnosa_ket: (vDataitemsArr[vArridx]||{}).diagnosa_ket || "",
			ket_gigi: (vDataitemsArr[vArridx]||{}).ket_gigi || "",
			tindakan: (vDataitemsArr[vArridx]||{}).tindakan || "",
		})
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile)
				document.getElementById("inpdiagnosacaption") &&
					document.getElementById("inpdiagnosacaption").focus();
		},350)
	}
	const hdlKlikDiagnosalookup = () => {
		//--LOOKUP--/
		const vDatalookupArr = uDatadiagnosaArr.map(vItems =>{ 
			return {id: vItems.id,caption:vItems.nama_diagnosa} 
		})

		uDlglookupObj.lookup_arr 	= vDatalookupArr;
		uDlglookupObj.dlg_header	= ((uBahasaObj.word_lookup||"Lookup")+": "+
			(uBahasaObj.caption_datadiagnosa||"Data Diagnosa"))

		uDlglookupObj.inputvalue 	= "";
		uDlglookupObj.jenislookup 	= "DIAGNOSA";
		setDlglookupshow(true)
		//--END LOOKUP--/
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return

		//--JIKA_DESKTOP--/
		if(uToggleditObj.jenis === "DIAGNOSA") {
			//console.log("(Rekammedisform - apiProsesedit) _IDITEM = "+_IDITEM);
			setToggleditObj({...uToggleditObj,value: _IDITEM});

			setTriggerediting(true);
			setDlglookupshow(false);		
			return;
		}
		//--END JIKA_DESKTOP--/

		const vArridx	= UFunc.getObjectIndeks(uDatadiagnosaArr,_IDITEM);
		setDlgformObj({...uDlgformObj,
			diagnosa_id: _IDITEM,
			diagnosa: (uDatadiagnosaArr[vArridx]||{}).nama_diagnosa || "Undf Diagnosa",
		});
		setDlglookupshow(false);		
	}
	const hdlKlikDlgformsimpan = () => {
		//--VALIDASI--/
		//--END VALIDASI--/

		apiProsesedit()
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMobile = () => {
		if(uHtml500msg) return "";

		return (
		<CCard className="classbgcard mb-3">
			<CCardHeader className="text-muted classfontsmaller py-0 pt-1">
				<small>Data RM</small>
			</CCardHeader>
			
			<CCardBody>
				<div className="d-flex justify-content-between my-1">
				<small className="pe-1">Tgl Periksa</small>

				<div className="text-end fw-bolder">{UFunc.TglAngka(uDatamainObj.tgl_periksa)}</div>
				</div>

				<div className="d-flex justify-content-between my-1">
				<small className="pe-1">Dokter</small>
				<div className="text-end fw-bolder text-primary">
					{uDatamainObj.dokter_caption || "-"}
				</div>
				</div>

				<div className="d-flex justify-content-between my-1">
				<small className="pe-1">Anamnesa</small>
				<div className="text-end fst-italic">
					{uDatamainObj.anamnase || "-"}
				</div>
				</div>
			</CCardBody>
		</CCard>
		)//->
	}
	const contentTanggal = () => {
		if(uHtml500msg) return "";

		return (
		<center>
			<b>{UFunc.TglAngka(uDatamainObj.tgl_periksa)}</b>
		</center>
		)//>
	}
	const contentDokter = () => {
		if(uHtml500msg) return "";

		return (
		<div className="text-center fw-bolder text-primary">
			{uDatamainObj.dokter_caption || "-"}
		</div>
		)//>
	}
	const contentAnamnase = () => {
		if(uHtml500msg) return "";
		if(uDataloading) return "";

		return (
		<div className="text-center fst-italic">
			{uDatamainObj.anamnase || "-"}
		</div>
		)//>
	}
	const contentItem = () => {
		if(uHtml500msg) return "";
		if(uDataloading) return (
			<CCard className="classbgcard mb-3">
				<CCardBody className="text-primary text-center py-md-3">
					<span className="classblinking">Loading..</span>
				</CCardBody>
			</CCard>
		)//>

		const vDataitemsArr	= uDatamainObj.items_arr || [];

		if(vDataitemsArr.length <= 0) return (
			<CCard className="classbgcard mb-3">
				<CCardHeader className="text-muted classfontsmaller py-0 pt-1">
					<small>Data RM</small>
				</CCardHeader>
				<CCardBody className="fst-italic text-center">&middot;&middot; Data Terapi Tidak Ada &middot;&middot;</CCardBody>
			</CCard>
		)

		return (
		<CForm>
		{vDataitemsArr.map((vItems,vKeys)=> {
			const {
				caption_terapi,tarif_terapi,diagnosa,diagnosa_ket,
				tindakan,ket_gigi,qty,
				id
			} = vItems;

			return (
			<CCard key={vKeys} className="classbgcard mb-3">
				<CCardHeader className="d-flex d-md-block justify-content-between">
					<span className="" style={{width:"5%"}}>{vKeys+1}.</span>
					<div className="d-md-inline-block px-1" style={{minWidth:"85%"}}>
					<b className="text-uppercase">{caption_terapi}</b>
					<span className="d-none d-md-inline-block">
						<span className="mx-1">&middot;</span>
						(
						<b className="text-primary">{UFunc.formatAngka(qty)}</b> UNT
						<span className="mx-1">x</span>
						Rp<b className="text-success">{UFunc.formatAngka(tarif_terapi)}</b>
						)

						<span className="mx-1">&middot;</span>

						<b>{UFunc.formatAngka(parseFloat(tarif_terapi) * parseFloat(qty))}</b>
					</span>
					</div>

					<CLink onClick={()=>hdlKlikMobileedit(id)} className="mx-0 classcursorpointer classikon classikonedit d-md-none"/>
				</CCardHeader>

				<CCardBody>
				<CRow className="align-items-stretch">
					<CCol md="6" className="classfontsmaller">
					<div className="d-flex justify-content-between">
					<small className="pe-1" style={{width:"30%"}}>Diagnosa</small>

					{(uToggleditObj.jenis === "DIAGNOSA" 
						&& uToggleditObj.tabel_id === parseInt(id)
						&& (uToggleditObj.is_processing||false) === true 
					) ? (
						<CSpinner size="sm" color="primary"/>
					) : (
					<>
						<div className="text-end" style={{width:"65%"}}>{diagnosa || "-"}</div>

						<CLink onClick={()=>hdlKlikToggleedit(id,"DIAGNOSA")} className="classcursorpointer ps-2 d-none d-md-block">
						<CIcon icon="cilPencil" className="classikonbox mx-0" height={23}/>
						</CLink>
					</>
					)}
					</div>

					<div className="classborderbottom my-2"/>

					<div className="d-flex justify-content-between">
					<small className="pe-1" style={{width:"30%"}}>Ket.Diagnosa</small>
					{(uToggleditObj.jenis === "DIAGNOSAKET" && uToggleditObj.tabel_id === parseInt(id)) ? (
					<>
						{(uToggleditObj.is_processing||false) === false ? (
						<CInputGroup size="sm">
							<CFormInput size="sm" maxLength={255}
								value={uToggleditObj.value||""}
								onKeyDown={(e)=>hdlKDownToggleedit(id,e)}
								onChange={(e)=>setToggleditObj({...uToggleditObj,value:e.target.value})}
								id="inpdiagnosaket"/>
							<CButton size="sm" color="dark" onClick={()=>hdlKlikToggleproses()}>
							<CIcon icon="cilCheck"/>
							</CButton>
						</CInputGroup>
						) : (
						<CSpinner size="sm" color="primary"/>
						)}
					</>
					) : (
					<>
						<div className="text-end" style={{width:"65%"}}>{diagnosa_ket || "-"}</div>

						<CLink onClick={()=>hdlKlikToggleedit(id,"DIAGNOSAKET")} className="classcursorpointer ps-2 d-none d-md-block">
						<CIcon icon="cilPencil" className="classikonbox mx-0" height={23}/>
						</CLink>
					</>
					)}
					</div>

					<div className="classborderbottom my-2"/>

					<div className="d-flex justify-content-between">
					<small className="pe-1" style={{width:"30%"}}>Nomor Gigi</small>
					{(uToggleditObj.jenis === "KETGIGI" && uToggleditObj.tabel_id === parseInt(id)) ? (
					<>
						{(uToggleditObj.is_processing||false) === false ? (
						<CInputGroup size="sm">
							<CFormInput size="sm" maxLength={50}
								value={uToggleditObj.value||""}
								onKeyDown={(e)=>hdlKDownToggleedit(id,e)}
								onChange={(e)=>setToggleditObj({...uToggleditObj,value:e.target.value})}
								id="inpnomorgigi"/>
							<CButton size="sm" color="dark" onClick={()=>hdlKlikToggleproses()}>
							<CIcon icon="cilCheck"/>
							</CButton>
						</CInputGroup>
						) : (
						<CSpinner size="sm" color="primary"/>
						)}
					</>
					) : (
					<>
						<div className="text-end" style={{width:"65%"}}>{ket_gigi || "-"}</div>

						<CLink onClick={()=>hdlKlikToggleedit(id,"KETGIGI")} className="classcursorpointer ps-2 d-none d-md-block">
						<CIcon icon="cilPencil" className="classikonbox mx-0" height={23}/>
						</CLink>
					</>
					)}
					</div>

					<div className="d-md-none classborderbottom my-2"/>
					</CCol>
					
					<CCol className="classfontsmaller">
					
					{(uToggleditObj.jenis === "TINDAKAN" && uToggleditObj.tabel_id === parseInt(id)) ? (
					<>
						{(uToggleditObj.is_processing||false) === false ? (
						<CInputGroup size="sm" className="align-items-start h-100">
						<CFormTextarea size="sm"
							className="h-100" 
							value={UFunc.br2nl(uToggleditObj.value||"")}
							onKeyDown={(e)=>hdlKDownToggleedit(id,e)}
							onChange={(e)=>setToggleditObj({...uToggleditObj,value:UFunc.nl2br(e.target.value)})}
							id="inptindakan"/>
						<CButton size="sm" color="dark" onClick={()=>hdlKlikToggleproses()}>
						<CIcon icon="cilCheck"/>
						</CButton>
						</CInputGroup>
						) : (
						<center><CSpinner size="sm" color="primary"/></center>
						)}
					</>
					) : (
					<>
					<div className="d-md-flex justify-content-between">
						<small className="pe-1 d-block" style={{color:"maroon"}}>Tindakan</small>
						
						<div className="d-md-none fst-italic">{UFunc.renderHTML(tindakan || "-")}</div>
						
						<div className="text-end d-none d-md-block fst-italic" style={{width:"85%"}}>{UFunc.renderHTML(tindakan || "-")}</div>
						
						<CLink onClick={()=>hdlKlikToggleedit(id,"TINDAKAN")} className="classcursorpointer ps-2 d-none d-md-block">
						<CIcon icon="cilPencil" className="classikonbox mx-0" height={23}/>
						</CLink>
					</div>
					</>
					)}
					</CCol>
				</CRow>
				</CCardBody>
			</CCard>
			)//->
		})}
		</CForm>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//->

		return (
		<CForm>
		<CRow className="my-1">
			<CCol xs="4"><small>Diagnosa</small></CCol>
			<CCol className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Pilih Diagnosa.."
					value={uDlgformObj.diagnosa}
					onChange={(e)=>setDlgformObj({...uDlgformObj,diagnosa: e.target.value})}
					id="inpdiagnosacaption"/>
				<CButton size="sm" color="dark" onClick={()=>hdlKlikDiagnosalookup()} id="btndiagnosalookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-1">
			<CCol xs="4"><small>Ket.Diagnosa</small></CCol>
			<CCol className="text-end">
				<CFormInput size="sm" maxLength={255}
					value={uDlgformObj.diagnosa_ket}
					onChange={(e)=>setDlgformObj({...uDlgformObj,diagnosa_ket: e.target.value})}
					id="inpdiagnosaket"/>
			</CCol>
		</CRow>

		<CRow className="my-1">
			<CCol xs="4"><small>Nomor Gigi</small></CCol>
			<CCol className="text-end">
				<CFormInput size="sm" maxLength={255}
					value={uDlgformObj.ket_gigi}
					onChange={(e)=>setDlgformObj({...uDlgformObj,ket_gigi: e.target.value})}
					id="inpnomorgigi"/>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-1">
			<CCol md="4"><small style={{color:"maroon"}}>Tindakan</small></CCol>
			<CCol className="text-end">
				<CFormTextarea size="sm"
					rows={5}
					value={UFunc.br2nl(uDlgformObj.tindakan)}
					onChange={(e)=>setDlgformObj({...uDlgformObj,tindakan: UFunc.nl2br(e.target.value)})}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan",e)}
					id="inptindakan"/>
			</CCol>
		</CRow>
		</CForm>
		)//->
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setDatamainObj({})
		setDataloading(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Rekammedisform - apiLoaddata) LEWAT ");
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : uRekammedisID,
			send_cabangid : uLastinitObj.cabangID || "0",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_formedit";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setDataloading(false);
			setLoading(false);
			if(output_string.tampil) {
				console.log("(Rekammedisform - apiLoaddata) output_string.dataobject : "+output_string.dataobject);
				setDatamainObj(JSON.parse(output_string.dataobject || "{}"));
				setDataanamanseArr(JSON.parse(output_string.dataanamnase || "[]"));
				setDatadiagnosaArr(JSON.parse(output_string.datadiagnosa || "[]"));
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedisform - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setDataloading(false);
			setLoading(false);
			console.log("(Rekammedisform - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesedit = () => {
		const vInitplatform	= uToggleditObj.jenis===undefined ? "MOBILE" : "DESKTOP";
		let vTabelID	= vInitplatform==="MOBILE" ? parseInt(uDlgformObj.tabel_id) : parseInt(uToggleditObj.tabel_id);
		const vArridx	= UFunc.getObjectIndeks((uDatamainObj.items_arr||[]),vTabelID);

		const vElbutton	= document.getElementById("btnDialogSimpan");
		let vDatamobileObj	= {};
		let vValue;
		if(vInitplatform !== "MOBILE") {
			let vValuechecked   = (((uDatamainObj.items_arr||[])[vArridx] || {}).diagnosa_ket || "").toUpperCase();
			if(uToggleditObj.jenis === "KETGIGI") {
				vValue 			= (uToggleditObj.value||"").trim().toUpperCase();
				vValuechecked = (((uDatamainObj.items_arr||[])[vArridx] || {}).ket_gigi || "").toUpperCase();
			} else if(uToggleditObj.jenis === "TINDAKAN") {
				vValue 			= (uToggleditObj.value||"").trim().toUpperCase();
				vValuechecked = (((uDatamainObj.items_arr||[])[vArridx] || {}).tindakan || "").toUpperCase();
			} else if(uToggleditObj.jenis === "DIAGNOSA") {
				vValuechecked 	= parseInt(((uDatamainObj.items_arr||[])[vArridx] || {}).diagnosa_id) || 0;
				vValue 			= parseInt(uToggleditObj.value) || 0;
			} else {
				vValue 			= (uToggleditObj.value||"").trim().toUpperCase();
			}

			if(vValuechecked === vValue) {
				//console.log("(Rekammedisform - apiProsesedit) "+vValuechecked+" === "+vValue);
				setToggleditObj({});
				return;
			}
		} else {
			vDatamobileObj	= {...uDlgformObj,
				dlg_header: undefined,
			}
			vElbutton && (vElbutton.disabled = true);
			setLoading(true);
		}

		setToggleditObj({...uToggleditObj,is_processing:true})
 
		const vDATAS	= JSON.stringify({
			send_rekammedisid : uRekammedisID,
			send_cabangid : uLastinitObj.cabangID || "0",
			send_editplatform : vInitplatform,
			send_datamobile: JSON.stringify(vDatamobileObj),
			send_editjenis : uToggleditObj.jenis,
			send_edititemid : vInitplatform!=="MOBILE" ? uToggleditObj.tabel_id : uDlgformObj.tabel_id,//--trekammedis_item.id
			send_editvalue : uToggleditObj.value,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_editrmitem";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setToggleditObj({...uToggleditObj,is_processing:false});
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				if(vInitplatform !== "MOBILE") {
					if(uToggleditObj.jenis === "DIAGNOSA"){
						(uDatamainObj.items_arr||[])[vArridx].diagnosa_id = 
							uToggleditObj.value; 

						const vDiagnosaidx = UFunc.getObjectIndeks(uDatadiagnosaArr,vValue);
						(uDatamainObj.items_arr||[])[vArridx].diagnosa = 
							(uDatadiagnosaArr[vDiagnosaidx]||{}).nama_diagnosa || "UNDF";
					} else if(uToggleditObj.jenis === "DIAGNOSAKET") {
						(uDatamainObj.items_arr||[])[vArridx].diagnosa_ket =
							uToggleditObj.value; 
					} else if(uToggleditObj.jenis === "KETGIGI") {
						(uDatamainObj.items_arr||[])[vArridx].ket_gigi =
							uToggleditObj.value; 
					} else if(uToggleditObj.jenis === "TINDAKAN") {
						(uDatamainObj.items_arr||[])[vArridx].tindakan =
							(uToggleditObj.value); 
					}

					setToggleditObj({});
				} else {
					(uDatamainObj.items_arr||[])[vArridx].diagnosa_id 	= vDatamobileObj.diagnosa_id;
					(uDatamainObj.items_arr||[])[vArridx].diagnosa 		= vDatamobileObj.diagnosa;
					(uDatamainObj.items_arr||[])[vArridx].diagnosa_ket 	= vDatamobileObj.diagnosa_ket;
					(uDatamainObj.items_arr||[])[vArridx].ket_gigi 		= vDatamobileObj.ket_gigi;
					(uDatamainObj.items_arr||[])[vArridx].tindakan 		= vDatamobileObj.tindakan;

					setDlgformshow(false);
				}
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekammedisform - apiProsesedit) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setToggleditObj({...uToggleditObj,is_processing:false});
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Rekammedisform - apiProsesedit) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});

		apiLoaddata();

		return () => {
			setDatamainObj({}); setDatadiagnosaArr([]); setDataanamanseArr([]);
			setToggleditObj({});
			setTriggerediting(false);
			setDlgformshow(false);setDlglookupshow(false);
		}
	},[])
	React.useEffect(()=>{
		if(!uTriggerediting) return;

		setTriggerediting(false);
		apiProsesedit();
	},[uTriggerediting])
	//---END EFFECT--/

	//console.log("(Rekammedisform) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top me-2" height={30}/>
			</CHeaderToggler>
			
			<CHeaderBrand className="me-auto text-normal">
				<b>Edit Rekam Medis</b>
			</CHeaderBrand>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
		<CContainer xxl className="px-0">
			{(uHtml500msg) ? (
			<CCard className="classbgcard my-4">
				<CCardHeader className="d-flex justify-content-between align-items-center">
					<div>Error Page 500</div>
					<CLink onClick={apiLoaddata} className="classcursorpointer classikon classikonreset"/>
				</CCardHeader>
				<CCardBody>
					<div className="m-lg-5">{UFunc.renderHTML(uHtml500msg)}</div>
				</CCardBody>
			</CCard>
			) : (
			<>
				{!uDataloading && (
				<CRow className="align-items-stretch">
				<CCol className="d-md-none">{contentMobile()}</CCol>

				<CCol md="3" className="mb-3 d-none d-md-block">
				<CCard className="classbgcard h-100">
				<CCardHeader className="text-muted classfontsmaller py-0 pt-1">
					<small>Tanggal Periksa</small>
				</CCardHeader>
				<CCardBody>{contentTanggal()}</CCardBody>
				</CCard>
				</CCol>

				<CCol md="4" className="mb-3 d-none d-md-block">
				<CCard className="classbgcard h-100">
				<CCardHeader className="text-muted classfontsmaller py-0 pt-1">
					<small>Dokter</small>
				</CCardHeader>
				<CCardBody>{contentDokter()}</CCardBody>
				</CCard>
				</CCol>

				<CCol md="5" className="mb-3 d-none d-md-block">
				<CCard className="classbgcard h-100">
				<CCardHeader className="text-muted classfontsmaller py-0 pt-1">
					<small>Anamnesa</small>
				</CCardHeader>
				<CCardBody>{contentAnamnase()}</CCardBody>
				</CCard>
				</CCol>
				</CRow>
				)}

				{contentItem()}
			</>
			)}
		</CContainer>
		</div>		
		</div>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_header||"Lookup Data"}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.lookup_arr||[]} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uISDlgformshow}
			dialogHeader={uDlgformObj.dlg_header||""}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)//>
}	

export default Rekammedisform